import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import {
    Button, TextField, Typography, Link, Box, Backdrop,
    Stack, CircularProgress, Divider, Collapse
} from '@mui/material';
import ReCAPTCHA from 'reaptcha';
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
    LoginUserCredentialsModel, LoginStage, ApiViewEntityResponse,
    LoginStageResultModel, LoginResultModel, LoginProvider
} from '../../interfaces';
import { loginUser, spaReset, passwordResetInit, initError, spaResetErrorMessages } from "../../app/loginSlice"
import ErrorsBox from '../errorsBox';
import { parseReturnUrl } from '../../functions';
import { IDENTITY_SERVER_URL, GOOGLE_RECAPTCHA_SITEKEY } from "../../constants";
import MicrosoftLoginButton from "./MicrosoftLoginButton"

export default function SignIn() {
    const [searchParams] = useSearchParams();
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null);
    const [reCaptchaVisible, setReCaptchaVisible] = useState<boolean>(false);
    const loginState = useAppSelector(store => store.login);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const captchaRef = useRef<ReCAPTCHA | null>(null);

    useEffect(() => {
        if (loginState?.userData == null) {
            window.location.href = '/' + window.location.search;
        }
        if (loginState?.password) {
            setPassword(loginState.password);
        }
    }, []);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let hasError: boolean = false;
        if (password.length == 0) {
            setPasswordError('Password could not be empty.');
            hasError = true;
        }
        else {
            setPasswordError('');
        }
        if (loginState.reCaptchaEnabled) {
            if (reCaptchaVisible && reCaptchaToken == null) {
                dispatch(initError("Please solve reCAPTCHA puzzle."));
                hasError = true;
            }
            else {
                dispatch(spaResetErrorMessages());
            }
        }
        if (hasError) {
            return;
        }

        const returnUrl = parseReturnUrl(searchParams.get("returnUrl"));
        const userCredentials: LoginUserCredentialsModel = {
            userNameOrEmail: loginState.userData?.userNameOrEmail as string,
            password: password,
            returnUrl: returnUrl,
            reCaptchaToken: reCaptchaToken
        };
        const dispatchResult = await dispatch(loginUser(userCredentials));
        if (dispatchResult.meta.requestStatus == "fulfilled") {
            const loginData = dispatchResult.payload as ApiViewEntityResponse<LoginStageResultModel<LoginResultModel>>;
            if (loginData.data.stage == LoginStage.Authenticated) {
                navigate({
                    pathname: '/client',
                    search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
                });
            }
            else if (loginData.data.stage == LoginStage.Mfa) {
                navigate({
                    pathname: '/mfa/providers',
                    search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
                });
            }
            else if (loginData.data.stage == LoginStage.ForcePasswordReset) {
                navigate({
                    pathname: '/password/change',
                    search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
                });
            }
            else if (loginData.data.stage == LoginStage.FillUserProfile) {
                navigate({
                    pathname: '/signin/profile',
                    search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
                });
            }
        }
        else if (loginState.reCaptchaEnabled) {
            captchaRef.current?.reset();
            setReCaptchaVisible(true);
            setReCaptchaToken(null);
        }
    };

    const handleSwitchAccount = () => {
        dispatch(spaReset());
        navigate({
            pathname: '/',
            search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
        });
    };

    const handleForgotPassword = () => {
        dispatch(passwordResetInit());
        navigate({
            pathname: '/password/forgot',
            search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
        });
    };

    const handleSigninWithMicrosoft = () => {
        window.location.href = `${IDENTITY_SERVER_URL}/v1/sso/microsoft${window.location.search}`;
    };

    return (
        <React.Fragment>
            <Box component="form" onSubmit={handleSubmit} width={1} autoComplete="off" noValidate>
                <Stack spacing={1.5}>
                    <Box>
                        <Typography id="signin-username-email-container" variant="subtitle1" fontWeight="bold">
                            {loginState?.userData?.userNameOrEmail}
                        </Typography>
                        <Typography variant="body1">
                            <Link id="signin-switchaccount-link" href="#" onClick={handleSwitchAccount}>Switch Account?</Link>
                        </Typography>
                    </Box>
                    <Box>
                        <ErrorsBox errors={loginState.messages} />
                        <TextField
                            inputProps={{ style: { height: 12 }, autoComplete: 'new-password' }}
                            margin="normal" id="password" name="password" type="password"
                            label="Enter password" autoComplete="current-password"
                            value={password}
                            onChange={e => { setPassword(e.target.value) }}
                            error={passwordError.length > 0}
                            helperText={passwordError}
                            required fullWidth autoFocus />
                        {loginState.reCaptchaEnabled &&
                            <Collapse in={loginState.messages.length > 0}>
                                <Box display="flex" flexDirection="row" justifyContent="center" pl="1px" pt={1}>
                                    <ReCAPTCHA sitekey={GOOGLE_RECAPTCHA_SITEKEY}
                                        onVerify={(token) => { setReCaptchaToken(token); }}
                                        onExpire={() => { setReCaptchaToken(null); }}
                                        ref={captchaRef} hl="en" />
                                </Box>
                            </Collapse>
                        }
                        <Button
                            id="signin-continue-button"
                            type="submit" fullWidth variant="contained" sx={{ mt: 2 }}
                        >
                            Continue
                        </Button>
                    </Box>
                    <Typography variant="body1" pt={1}>
                        <Link id="signin-forgotpassword-link" href="#" onClick={handleForgotPassword}>Forgot password?</Link>
                    </Typography>
                    {loginState.userData?.providers.includes(LoginProvider.Microsoft) &&
                        <React.Fragment>
                            <Box mt={1.5} pb={1} pl={3} pr={3}>
                                <Divider><Typography variant="body2" color="text.secondary">OR</Typography></Divider>
                            </Box>
                            <MicrosoftLoginButton onLoginClick={handleSigninWithMicrosoft} />
                        </React.Fragment>
                    }
                </Stack>
                <TextField id="email" name="email" autoComplete="email" size='small'
                    sx={{ visibility: "hidden", width: 0, height: 0, position: "absolute" }}
                    value={loginState.userData?.userNameOrEmail} />
            </Box>
            <Backdrop open={loginState.loading} className="loading">
                <CircularProgress />
            </Backdrop>
        </React.Fragment>
    );
}
